import React from "react";
import Layout from "./layout";
import { PageContextProvider } from "../context/PageContext";

export default (props: any) => {
  return (
    <PageContextProvider>
      <Layout settings={props.settings} pageContext={props.pageContext}>
        {props.children}
      </Layout>
    </PageContextProvider>
  );
};
