import React, { useContext, useEffect } from "react";
import Layout from "../layouts/";

import DynamicComponent from "../components";

import useStoryblok from "../lib/storyblok";

import PageContext from "../context/PageContext";

interface EditorProps {
  location: Location;
}

const EditorPage: React.FC<EditorProps> = ({ location }) => {
  let { story, settings } = useStoryblok(location);

  const pageCtx = useContext(PageContext);
  useEffect(() => {
    pageCtx.set &&
      pageCtx.set({
        settings,
        story,
        pagePath: story?.full_slug,
      });
  }, [story, settings]);

  if (story && settings)
    return (
      <Layout settings={settings}>
        <DynamicComponent
          key={story.content._uid}
          content={story.content}
          story={story}
          blok={story.content}
          path={`/${story.full_slug}/`}
        />
      </Layout>
    );

  return null;
};

export default EditorPage;
